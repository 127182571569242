@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Myriad";
    font-style: normal;
    font-weight: 400;
    src: url(/public/fonts/myriad/MYRIADPRO-REGULAR.woff) format("woff");
  }
  @font-face {
    font-family: "Myriad";
    font-style: normal;
    font-weight: 700;
    src: url(/public/fonts/myriad/MYRIADPRO-BOLD.woff) format("woff");
  }
}

.mh {
  height: calc(100vh - 80px);
}

@media (min-width: 1280px) {
  .ww {
    width: calc(100vw - 150px);
  }

  .bgg {
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 1800px auto;
  }
}

html {
  scroll-behavior: smooth;
}
